//import "bootstrap/dist/css/bootstrap.css";
import "./prestart";
import "./scss/custom.scss";
import "./scss/materialize/materialize.scss";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import { lazy, Suspense } from "react";
import { Spinner } from "reactstrap";
import { localStorageUtil } from "./utils/localStorageUtil";

const rootElement = document.getElementById("root");
const theme = localStorageUtil.getItem("THEME");

const App = lazy(() => import("./App"));

ReactDOM.render(
  <Suspense
    fallback={
      <div className={`fallback-spinner ${theme}`}>
        <Spinner style={{ height: "3rem", width: "3rem" }} />
      </div>
    }
  >
    <App />
  </Suspense>,
  rootElement
);
