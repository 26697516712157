type LocalStorageKey =
  | "APP_WORK_ENGINE_SIDEBAR_SIZE"
  | "APP_SELECTED_FLOW_ID"
  | "THEME"
  | "CURRENT_URL";

const getItem = (key: LocalStorageKey) => {
  return localStorage.getItem(key);
};

const setItem = (key: LocalStorageKey, data: any) => {
  localStorage.setItem(key, data);
};

const removeItem = (key: LocalStorageKey) => {
  localStorage.removeItem(key);
};

export const localStorageUtil = {
  getItem,
  setItem,
  removeItem,
};
