// Modify console.warn to control what's being displayed on dev tools.
const consoleWarn = console.warn;
console.warn = function(message) {

  // disable all unfixable warnings that are coming from third party components.
  // this is to clean up the dev tool console so it would not look so populated.
  // to only see warnings that are fixable.
  if (/componentWillMount has been renamed/gi.test(message)) return;
  if (/componentWillReceiveProps has been renamed/gi.test(message)) return;
  if (/componentWillUpdate has been renamed/gi.test(message)) return;

  // list of components that uses old function names:
  // componentWillMount:
  //    react-sizable
  //    react-slick
  //    react-scrollbar
  //    react-router-dom
  //
  // componentWillReceiveProps:
  //    react-dates
  //    react-scrollbar
  //
  // componentWillUpdate:
  //    react-dates
  //    react-scrollbar

  return consoleWarn.apply(this, arguments);
};